import "./assets/style.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { useAuthStore } from "@/stores/authStore";

import App from "./App.vue";
import router from "./router";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

const app = createApp(App);

// Créez l'instance de Pinia et utilisez-la avec l'app
const pinia = createPinia();
app.use(pinia);

// Initialisez le store auth avant le montage de l'app
const authStore = useAuthStore();
authStore.initializeStore();

// Utilisez le routeur avec l'app
app.use(router);

// Initialisez Bugsnag avec votre clé d'API
Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
})
const bugsnagVue = Bugsnag.getPlugin('vue')
app.use(bugsnagVue)

app.mount("#app");
